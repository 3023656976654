
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Tasks - '
  },
  name: 'Tasks',
  components: {},
  data: () => ({}),
  computed: {}
});
